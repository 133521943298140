<template>
  <div class="video-item">
  <transition name="fade-fast">
    <vue-plyr ref="plyr" :style="videoStyle">
      <video
        controls
        playsinline
      >
      <source
        :src="video.sources[0].src"
        type="video/mp4"
      />
      </video>
    </vue-plyr>
    <!--myVideo
      :sources="video.sources"
      :options="video.options"
      :style="videoStyle"
      class="video-item__el"></myVideo-->
  </transition>
  </div>
</template>

<script>
//https://github.com/hilongjw/vue-video
//import myVideo from 'vue-video';

export default {
  name: 'VideoFile',
  components: {
    //myVideo,
  },
  props: {
    src: String,
    width: String,
    height: String,
    isVisible: Boolean,
  },
  data() {
    return {
      video: {
          sources: [{
              src: '',
              type: 'video/mp4'
          }],
          options: {
              autoplay: false,
              volume: 0.6,
          }
      },
    };
  },
  mounted() {
    this.video.sources[0].src = this.src;

    //console.log(this.$refs.plyr.player)
  },
  computed: {
    videoStyle() {
      return {
        width: this.width,
        height: this.height,
      };
    },
  },
  watch: {
    src(val) {
      this.video.sources[0].src = val;
    },
  },
  methods: {
  },
};
</script>


<style lang="scss">
$content-padding-left: $navbar-logo-size + ($navbar-padding * 2);

.video-item {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  padding-right: $content-padding-left;
  padding-bottom: 100px;
  @media screen and (max-width: $mobile-screen-width) {
    padding-right: 0;
  }

  &__el {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    //object-fit: contain;

    & .container {
      height: 100%;
      //width: 100%;
      & .__cov-video-container {
        height: 100%;
        //width: 100%;
      }
    }
  }
}

</style>