//import mixpanel from 'mixpanel-browser';
import Vue from 'vue';
import VueGtag, { bootstrap, setOptions } from 'vue-gtag';

const TRACKING_ID = 'G-DSB9QNXCSS';

let isActive = false;

export default {

  gtag: null,
  optedIn: false,


  init(router) {
    Vue.use(VueGtag, {
      bootstrap: false,
    }, router);

    return this;
  },

  optOut() {
    this.optedIn = false;
    this.checkInit();

    if (this.isActive) {
      if (this.optedIn) {
        Vue.$gtag.optIn();
      } else {
        Vue.$gtag.optOut();
      }
    }
  },

  optIn() {
    this.optedIn = true;
    this.checkInit();

    if (this.isActive) {
      if (this.optedIn) {
        Vue.$gtag.optIn();
      } else {
        Vue.$gtag.optOut();
      }
    }
  },

  checkInit() {
    setOptions({
      config: { id: TRACKING_ID },
      enable: this.optedIn,
    });

    const self = this;
    return new Promise(function (resolve) {
      try {
        bootstrap(Vue.$gtag).then(() => {
          //self.gtag = gtag;
          self.gtag = Vue.$gtag;
          isActive = true;

          if (self.optedIn) {
            Vue.$gtag.optIn();
          } else {
            Vue.$gtag.optOut();
          }

          resolve();
        });
      } catch (e) {
        resolve();
      }
    });
  },

  /**
   * Identify a user with a unique ID instead of a Mixpanel randomly generated distinct_id.
   * If the method is never called, then unique visitors will be identified by a UUID generated
   * the first time they visit the site.
   * @param {*} uniqueId
   */
  identify(userKey) {
    if (isActive === true) {
      this.gtag.config({
        user_id: userKey,
      });
    }
  },


  /**
   * Track an event. This is the most important and frequently used Mixpanel function.
   * @param {*} eventName
   * @param {*} properties
   */
  track(eventName, properties) {
    if (isActive === true) {
      if (!properties) {
        properties = {};
      }

      properties.send_to = TRACKING_ID;
      this.gtag.event(eventName, properties);

      this.gtag.pageview(eventName);
    }
  },

};
