<template>
  <div class="menu-triangle">
    <div class="menu-triangle__list">

      <div
        v-for="(projects, row) in itemRows"
        :key="row"
        :class="rowClass">
        <Triangle
          v-for="(item, index) in projects"
          :key="index"
          :item="item"
          :selected="item.id === selectedId"
          class="menu-triangle__list__row__item" />
      </div>

    </div>
  </div>
</template>

<script>
import Triangle from '@/components/menu/Triangle.vue';

export default {
  name: 'MenuTriangle',
  components: {
    Triangle,
  },
  props: {
    items: Array,
    showStackList: Boolean,
    selectedId: String,
  },
  data() {
    return {
      //itemRows: []
    }
  },

  // Computed ********************************
  computed: {
    itemRows() {
      const items = [];
      for (let i = 1; i <= 7; i++) {
        const p = this.items.filter(x => x.project_color.id === `${i}`);
        items[7 - i] = p;
      }
      return items;
    },
    rowClass() {
      const classes = {
        'menu-triangle__list__row': true
      }
      if (this.showStackList) {
        classes['menu-triangle__list__row--stack'] = true;
      }
      return classes;
    },
  },

  // Mounted ********************************
  mounted() {
  },
  // Created ********************************
  created() {
  },
}
</script>

<style lang="scss" scoped>

.menu-triangle {
  display: flex;
  justify-content: center;

  &__list {
    display: flex;
    flex-direction: column;

    &__row {
      display: flex;
      flex-direction: row;

      &--stack {
        flex-direction: column;
      }
    }
  }
}

</style>
