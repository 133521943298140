<template>
  <div class="menu-square">
    <div :class="classList">
      <div
        :class="classItem('me')">
        <router-link
          :to="{ name: 'About', params: { menu: 'me' }}"
          class="menu-square__list__item__link"
          v-tooltip="aboutTooltipContent"
          @mousedown.native="onClick" />
      </div>
      <div
        :class="classItem('bio')">
        <router-link
          :to="{ name: 'About', params: { menu: 'bio' }}"
          class="menu-square__list__item__link"
          v-tooltip="bioTooltipContent"
          @mousedown.native="onClick" />
      </div>
      <div
        :class="classItem('cv')">
        <router-link
          :to="{ name: 'About', params: { menu: 'cv' }}"
          class="menu-square__list__item__link"
          v-tooltip="cvTooltipContent"
          @mousedown.native="onClick" />
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'MenuSquare',
  props: {
    selectedId: String,
  },

  // Computed ********************************
  computed: {
    classList() {
      const classes = {};
      classes['menu-square__list'] = true;
      classes['menu-square__list--project'] = this.selectedId ?? false;
      return classes;
    },
    aboutTooltipContent() {
      const tooltipContent = this.tooltipContent();
      tooltipContent.content = 'About';
      return tooltipContent;
    },
    bioTooltipContent() {
      const tooltipContent = this.tooltipContent();
      tooltipContent.content = 'Bio';
      return tooltipContent;
    },
    cvTooltipContent() {
      const tooltipContent = this.tooltipContent();
      tooltipContent.content = 'CV';
      return tooltipContent;
    },
  },

  methods: {
    classItem(itemId) {
      const classes = {};
      classes['menu-square__list__item'] = true;
      classes['menu-square__list__item--selected'] = this.selectedId === itemId || this.$store.getters.isVisited(itemId);
      classes['menu-square__list__item--project'] = this.selectedId ?? false;
      return classes;
    },
    tooltipContent() {
      return {
        content: '',
        offset: this.$constants.TOOLTIP_OFFSET,
        placement: 'right',
        hideOnTargetClick: true,
        delay: { show: 200, hide: 0 },
        classes: 'tooltip_square',
      };
    },

    onClick(event) {
      this.$root.clickPosX = event.clientX;
      this.$root.clickPosY = event.clientY;
    },
  },
}
</script>

<style lang="scss" scoped>

.menu-square {

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: $mobile-screen-width) {
      flex-direction: row;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &--project {
      @media screen and (max-width: $mobile-screen-width) {
        flex-direction: column;
        justify-content: initial;
        margin-top: 0;
        margin-bottom: 0;
      } 
    }

    &__item {
      position: relative;
      flex: 0 0 auto;
      width: 40px;
      height: 40px;
      padding: 0;
      margin: 0;
      border: 2px solid $theme-color-primary;
      border-radius: 2px;
      transform: rotate(-45deg);
      box-sizing: content-box;
      user-select: none;
      //border-top: 14px solid $theme-color-primary;

      &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        height: 12px;
        background: $theme-color-primary;
        z-index: -1;
        transition: height .1s ease-in-out 0s;
      }

      @media (hover) {
        &:hover:before {
          height: 42px;
          //height: 0;
        }
      }

      &--selected:before {
        height: 42px;
      }

      &__link {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &__item + &__item {
      margin-top: 40px;
      transform: rotate(45deg);

      @media screen and (max-width: $mobile-screen-width) {
        margin-top: 0;
        margin-left: 40px;
      }
    }

    &__item--project + &__item--project {
      @media screen and (max-width: $mobile-screen-width) {
        margin-top: 40px;
        margin-left: 0;
      }
    }

    &__item + &__item + &__item {
      transform: rotate(135deg);
    }

  }
}

</style>
