<template>
  <div class="about">
    <div ref="navbar" class="about__navbar">
      <div class="about__navbar__inner">
        <div class="about__navbar__logo">
          <router-link :to="{ name: 'Home' }" title="Home - Guillaume Vieira" class="about__navbar__logo__link">
            <img class="about__navbar__logo__link__img" src="@/assets/logo_about.svg" />
          </router-link>
        </div>
        <div class="about__navbar__info">
          <div class="about__navbar__info__title_container">
            <h1 class="about__navbar__info__title_container__title">
              {{ title }}
            </h1>
          </div>
        </div>
        <!-- <div v-if="$utils.isTouchDevice()" :class="menuButtonClasses" @click="projectMenuPressed">
          <img :src="menuButtonImage" class="about__navbar__menuBtn__img" />
        </div> -->
      </div>
    </div>

    <div class="about__main">
      <div class="about__main__menu">
        <MenuSquare class="about__main__menu__component" :selected-id="menu" />
      </div>

      <div class="about__main__content" v-html="content" />
    </div>
  </div>
</template>

<script>
import MenuSquare from "@/components/menu/MenuSquare.vue";
import menuSquare from "@/assets/menu-quadrado.svg";

export default {
  name: "About",
  components: {
    MenuSquare,
  },
  props: {},
  data() {
    return {
      menu: null,
      content: null,
    };
  },

  // Created ********************************
  created() {
    if (!this.$route.params.menu || this.$route.params.menu === "") {
      this.$router.replace({ name: 'Home' });
    }

    this.resetContent();
  },

  // Computed ********************************
  computed: {
    title() {
      if (this.menu === "me") {
        return "About";
      } else if (this.menu === "bio") {
        return "Bio";
      } else if (this.menu === "cv") {
        return "Curriculum Vitae";
      }
      return "";
    },
    menuButtonClasses() {
      const classes = {
        about__navbar__menuBtn: true,
        "about__navbar__menuBtn--square": true,
      };
      return classes;
    },
    menuButtonImage() {
      return menuSquare;
    },
  },

  watch: {
    $route() {
      this.resetContent();
    },
  },

  methods: {
    resetContent() {
      this.content = null;
      this.menu = this.$route.params.menu?.toLowerCase();
      this.requestContent();

      this.$store.dispatch("setVisited", {
        key: this.menu,
        value: true,
      });
    },
    requestContent() {
      if (this.menu === "me") {
        if (!this.$store.getters.menu.about) {
          this.$http.getAbout(
            this.menu,
            (response) => {
              this.setContent(response);
              this.$store.dispatch("setMenuAbout", response);
            },
            (error) => {
              this.content = null;
              console.log(error);
            }
          );
        } else {
          this.setContent(this.$store.getters.menu.about);
        }
        this.$analytics.track("Visit About");
      } else if (this.menu === "bio") {
        if (!this.$store.getters.menu.bio) {
          this.$http.getAbout(
            this.menu,
            (response) => {
              this.setContent(response);
              this.$store.dispatch("setMenuBio", response);
            },
            (error) => {
              console.log(error);
              this.content = null;
            }
          );
        } else {
          this.setContent(this.$store.getters.menu.bio);
        }
        this.$analytics.track("Visit Bio");
      } else if (this.menu === "cv") {
        if (!this.$store.getters.menu.cv) {
          this.$http.getAbout(
            this.menu,
            (response) => {
              this.setCVContent(response);
              this.$store.dispatch("setMenuCV", response);
            },
            (error) => {
              console.log(error);
              this.content = null;
            }
          );
        } else {
          this.setCVContent(this.$store.getters.menu.cv);
        }
        this.$analytics.track("Visit CV");
      } else {
        this.content = null;
      }
    },
    setContent(text) {
      if (text && text.length > 0) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(
          text.replaceAll("&lt;", "<").replaceAll("&gt;", ">"),
          "text/html"
        );
        //this.content = xmlDoc.documentElement.textContent;
        this.content = xmlDoc.body.innerHTML;
      } else {
        this.content = null;
      }
    },

    setCVContent(obj) {
      if (obj && obj.length > 0) {
        var elements = "";
        obj.forEach((element) => {
          /*
          title
          order
          items
            id
            year
            title
            description
            order
          */
          elements += `<h2 class="about__main__content__section-title">${element.title}</h2>`;
          elements += '<div class="about__main__content__section">';
          if (element.items && element.items.length > 0) {
            element.items.forEach((item) => {
              elements += '<div class="about__main__content__row">';
              elements += `<span class="about__main__content__row__col1">${item.year}</span>`;
              const parser = new DOMParser();
              const xmlDoc = parser.parseFromString(
                item.description
                  .replaceAll("&lt;", "<")
                  .replaceAll("&gt;", ">"),
                "text/html"
              );
              elements += `<div class="about__main__content__row__col2">
                  <span class="about__main__content__row__col2__title">${item.title}</span>
                  <div class="about__main__content__row__col2__content">${xmlDoc.body.innerHTML}</div>
                </div>`;
              elements += "</div>";
              //console.log(xmlDoc.body.innerHTML);
            });
            elements += "</div>";
          }
        });
        //const parser = new DOMParser();
        //const xmlDoc = parser.parseFromString(elements, 'text/html');
        //this.content = xmlDoc.documentElement.textContent;
        this.content = elements;
      } else {
        this.content = null;
      }
    },
    projectMenuPressed() {
      if (this.$utils.isTouchDevice()) {
        // TODO
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$content-padding-left: $navbar-logo-size + ($navbar-padding * 2);

.about {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  text-align: left;

  &__navbar {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    z-index: 1;

    &__inner {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    &__menuBtn {
      flex: 0 0 auto;
      //position: fixed;
      //top: 2px;
      //right: -4px;
      //width: 30px;
      align-self: flex-start;
      padding-left: 10px;
      padding-bottom: 10px;

      &__img {
        width: 30px;
      }

      &--triangle,
      &--square {
        margin-top: 18px;
        margin-right: 8px;
      }
    }

    @media screen and (max-width: $mobile-screen-width) {
      flex-direction: column;
      align-items: start;
    }

    &__logo {
      padding: $navbar-padding;

      @media screen and (max-width: $mobile-screen-width) {
        padding: $navbar-padding-mobile;
        padding-bottom: 16px;
      }

      &__link {
        &__img {
          width: $navbar-logo-size;
          height: $navbar-logo-size;

          @media screen and (max-width: $mobile-screen-width) {
            width: $navbar-logo-size-mobile;
            height: $navbar-logo-size-mobile;
          }
        }
      }
    }

    &__info {
      //width: 100%;
      flex: 1 1 auto;
      display: flex;
      flex-direction: row;
      align-items: center;

      &__title_container {
        flex: 1 1 auto;
        padding-right: $navbar-padding;
        padding-left: $navbar-logo-size - $navbar-padding + 14px; //$content-padding-left;

        @media screen and (max-width: $mobile-screen-width) {
          //padding-left: $navbar-padding-mobile + 6px;
          //padding-right: $navbar-padding-mobile + 6px;
          //padding-bottom: $navbar-padding-mobile;
          padding-left: 6px;
          padding-right: 6px;
          padding-bottom: 0;
        }

        &__title {
          margin: 0;
          font-family: $navbar-title-font;
          font-weight: $navbar-title-font-weight;
          font-size: $navbar-title-size;
          line-height: $navbar-title-line-height;
          color: $theme-color-primary;

          @media screen and (max-width: $mobile-screen-width) {
            font-size: $navbar-title-size-mobile;
            line-height: $navbar-title-line-height-mobile;

            display: block;
            text-overflow: ellipsis;
            word-wrap: break-word;
            overflow: auto;
            max-height: $navbar-title-line-height * 1.8;
          }
        }
      }

      &__caption_container {
        max-width: 536px;
        margin-right: 30px;
        padding: 16px 0;
        overflow-y: auto;
        max-height: 116px;

        &__text {
          font-size: 14px;
          line-height: 20px;

          &::v-deep * {
            padding: 0;
            margin: 0;
          }
        }

        @media screen and (max-width: $mobile-screen-width) {
          display: none;
        }
      }

      &__navigation {
        display: flex;
        flex-direction: row;
        padding-right: $navbar-padding;

        @media screen and (max-width: $mobile-screen-width) {
          display: none;
        }

        &__arrow {
          font-size: 20px;
          color: $theme-color-primary;
          font-weight: bold;
          padding: 14px;
          margin: 0 28px;
          cursor: pointer;
          user-select: none;

          // &--left {}
          // &--right {}

          &--disabled {
            cursor: not-allowed;
            opacity: 0.3;
          }
        }
      }
    }
  }

  /*
  &__navbar {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;

    @media screen and (max-width: $mobile-screen-width) {
      flex-direction: column;
      align-items: start;
    }

    &__logo {
      padding: $navbar-padding;

      @media screen and (max-width: $mobile-screen-width) {
        padding: $navbar-padding-mobile;
        padding-bottom: 16px;
      }

      &__link {
        &__img {
          width: $navbar-logo-size;
          height: $navbar-logo-size;

          @media screen and (max-width: $mobile-screen-width) {
            width: $navbar-logo-size-mobile;
            height: $navbar-logo-size-mobile;
          }
        }
      }
    }

    &__title_container {
      width: 100%;
      padding-right: $navbar-padding;
      padding-left: $navbar-logo-size + 14px;//$content-padding-left;

      @media screen and (max-width: $mobile-screen-width) {
        padding-left: $navbar-padding-mobile + 6px;
        padding-right: $navbar-padding-mobile + 6px;
        padding-bottom: $navbar-padding-mobile;
      }

      &__title {
        margin: 0;
        font-family: $navbar-title-font;
        font-weight: $navbar-title-font-weight;
        font-size: $navbar-title-size;
        line-height: $navbar-title-line-height;
        color: $theme-color-primary;

        @media screen and (max-width: $mobile-screen-width) {
          font-size: $navbar-title-size-mobile;
          line-height: $navbar-title-line-height-mobile;

          display: block;
          text-overflow: ellipsis;
          word-wrap: break-word;
          overflow: hidden;
          max-height: $navbar-title-line-height * 2;
        }
      }
    }
  }*/

  &__main {
    display: flex;
    flex-direction: row;
    padding-right: $content-padding;

    @media screen and (max-width: $mobile-screen-width) {
      padding-right: $navbar-padding-mobile + 6px;
      padding-left: $navbar-padding-mobile + 6px;
    }

    &__menu {
      flex: 0 0 auto;
      width: $content-padding-left;
      margin-top: 20px + 10px;
      position: relative;

      @media screen and (max-width: $mobile-screen-width) {
        display: none;
      }

      &__component {
        transform-origin: top;
        transform: scale(0.6);
        top: $navbar-logo-size + ($navbar-padding * 2) + 34px;
        position: sticky;
      }
    }

    &__content {
      width: 100%;
      //max-width: 600px + $content-padding-left;
      max-width: 616px;
      font-size: 14px;
      line-height: 16px;
      margin-top: 20px;
      //padding-left: $navbar-logo-size + 14px;
      padding-left: $navbar-logo-size - $navbar-padding + 14px;
      padding-bottom: 60px;

      @media screen and (max-width: $mobile-screen-width) {
        max-width: 100%;
        padding-left: 0;
        font-size: 16px;
        line-height: 20px;
      }

      &::v-deep &__section-title {
        color: $theme-color-primary;
        margin: 0 0 40px 0;
        //font-weight: 300;
        font-size: 16px;
      }

      &::v-deep &__section {
        margin-bottom: 60px;
      }

      &::v-deep &__row {
        display: flex;
        flex-direction: row;
        margin-top: 20px;

        &__col1 {
          flex: 0 0 auto;
          min-width: 90px;
          color: $theme-color-primary;
          font-style: normal;
        }

        &__col2 {
          flex: 1 1 auto;
          margin: 0;
          display: flex;
          flex-direction: column;

          &__title {
            color: $theme-color-primary;
            //font-weight: bold;
          }

          &__content {
            & p {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
</style>
