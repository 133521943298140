<template>
  <div ref="container" id="app-animation-container" v-show="isAnimatingTransition">
    <div ref="circle" id="app-animation-circle" v-show="animate" :class="animate ? 'is-animating' : ''"
      :style="circleStyle" />
  </div>
</template>

<script>
export default {
  name: 'TransitionAnimation',
  props: {
    isAnimatingTransition: {
      type: Boolean,
      default: false,
    },
    xPos: {
      type: Number,
      default: 0,
    },
    yPos: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: '#2325E2',
    },
  },

  data() {
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      animate: false,
    };
  },

  computed: {
    circleStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
        top: `${this.top}px`,
        left: `${this.left}px`,
        background: this.color,
      };
    },
  },

  watch: {
    isAnimatingTransition(val) {
      if (val) {
        this.$nextTick(() => {
          let container = this.$refs.container;

          let d = Math.max(container.clientHeight, container.clientWidth);
          this.width = d;
          this.height = d;

          this.top = this.yPos - (d * 0.5);
          this.left = this.xPos - (d * 0.5);

          this.animate = true;
        });
      } else {
        this.animate = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#app-animation-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;

  & #app-animation-circle {
    display: block;
    position: absolute;
    //background: rgb(0,0,0);
    border-radius: 50%;
    //opacity: 0;
    //transform: scale(0);
    transform: scale(2.5);
    transform-origin: center;
    opacity: 0;

    &.is-animating {
      animation: clickTransition 1s linear;
    }
  }
}

@keyframes clickTransition {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(2.5);
  }

  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
</style>
