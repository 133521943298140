<template>
    <div class="socials">
        <a v-if="emailAddress" :href="emailAddress">email</a>
        <a v-if="instagramLink" :href="instagramLink" target="_blank">instagram</a>
    </div>
</template>

<script>
export default {
    name: "Socials",
    components: {

    },
    props: {
        instagram_link: String,
        email: String,
    },
    computed: {
        instagramLink() {
            if (this.instagram_link) return this.instagram_link;
            return null;
        },
        emailAddress() {
            if (this.email) return `mailto:${this.email}`;
            return null;
        },
    },
};
</script>

<style lang="scss" scoped>
.socials {
    //margin-top: 80px;

    &::v-deep a {
        color: white;
        font-size: 0.8em;
        margin-left: 16px;

        @media screen and (max-width: $mobile-screen-width) {
            font-size: 1em;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}
</style>
