import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import analytics from './analytics/analytics'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

Vue.config.productionTip = false

/**
 *  API requests
 */
import requests from './http/requests';
Vue.prototype.$http = requests;


/**
 * Tooltip
 * https://github.com/Akryum/v-tooltip
 */
import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

/**
 * Touch events for swipe
 * https://www.npmjs.com/package/vue2-touch-events
 */
import Vue2TouchEvents from 'vue2-touch-events';
Vue.use(Vue2TouchEvents);

/**
 * VueSnip
 * https://github.com/ajobi/vue-snip
 */
//import VueSnip from 'vue-snip'
//Vue.use(VueSnip)

// https://github.com/josephschmitt/Clamp.js
// eslint-disable-next-line no-unused-vars
import clamp from '@/utils/Clamp';
Vue.prototype.$clamp = clamp.clamp;

/**
 * ProgressBar
 * https://github.com/hilongjw/vue-progressbar
 */
import VueProgressBar from 'vue-progressbar';

 
const options = {
  color: '#2325E2',
  failedColor: '#874b4b',
  thickness: '3px',
  transition: {
    speed: '0.3s',
    opacity: '0.6s',
    termination: 300,
  },
  autoRevert: true,
  autoFinish: false,
}
Vue.use(VueProgressBar, options)


/**
 * Transition Animation
 */

 import TransitionAnimation from './components/utils/TransitionAnimation';
 Vue.component('transition-animation', TransitionAnimation);

/**
 * Constants
 */
import constants from './utils/Constants'
Vue.prototype.$constants = constants;

/**
 * Utils
 */
 import utils from './utils/Utils'
 Vue.prototype.$utils = utils;

 /**
 * Analytics Lib
 */
Vue.prototype.$analytics = analytics.init();


/**
 * Video player
 * https://github.com/redxtech/vue-plyr
 */
Vue.use(VuePlyr, {
  plyr: {}
})


export default new Vue({
  router,
  store,
  data: {
    isAnimatingTransition: false,
    clickPosX: 0,
    clickPosY: 0,
    projectColor: null,
    isMobile: utils.isTouchDevice(),
    isMobileWidth: window.innerWidth <= constants.MOBILE_MAX_WIDTH,
  },
  created() {
    analytics.enableTracking();
  },
  /*watch: {
    isAnimatingTransition(val) {
      console.log(`isAnimatingTransition: ${val}`);
    },
  },*/
  render: h => h(App)
}).$mount('#app')
