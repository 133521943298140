<template>
    <div class="home-wrapper">
        <router-view></router-view>
    </div>
</template>

<script>

export default {
    name: 'HomeWrapper',
}
</script>