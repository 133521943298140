import Vue from 'vue'
import VueRouter from 'vue-router'
import Root from '../views/Root.vue'
import Home from '../views/Home.vue'
import HomeWrapper from '../views/HomeWrapper.vue'
import Project from '../views/Project.vue'
import About from '../views/About.vue'
import Error404 from '../views/404.vue'
import constants from '../utils/Constants'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Root',
    component: Root,
  },
  {
    path: '/artistic',
    component: HomeWrapper,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
      },
      {
        path: 'about/:menu',
        name: 'About',
        component: About,
      },
      {
        path: 'project/:menu/:project_id',
        name: 'Project',
        props: true,
        component: Project,
      },
    ]
  },
  // {
  //   path: '/about/:menu',
  //   name: 'About',
  //   component: About,
  // },
  // {
  //   path: '/project/:menu/:project_id',
  //   name: 'Project',
  //   props: true,
  //   component: Project,
  // },
  {
    path: '*',
    name: 'Error',
    component: Error404,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
})

router.beforeEach((to, from, next) => {
  if (['About', 'Project'].includes(to.name)) {
    if (to.params.projectColor) {
      router.app.$root.projectColor = to.params.projectColor;
    } else {
      router.app.$root.projectColor = constants.THEME_COLOR_PRIMARY;
    }
    router.app.$root.isAnimatingTransition = true;
    setTimeout(() => {
      next();
    }, 200);
    setTimeout(() => {
      router.app.$root.isAnimatingTransition = false;
    }, 1000);
  } /*else if (['Root'].includes(from.name)) {
    console.log('should animate');
    router.app.$root.projectColor = constants.THEME_COLOR_PRIMARY;
    router.app.$root.clickPosX = 0;
    router.app.$root.clickPosY = 0;
    router.app.$root.isAnimatingTransition = true;
    setTimeout(() => {
      next();
    }, 200);
    setTimeout(() => {
      router.app.$root.isAnimatingTransition = false;
    }, 1000);
  } */else {
    next();
  }
});

router.afterEach((to) => {
  let title = null;

  if (to.params.projectName) {
    title = to.params.projectName;
  } else if (to.params.menu === 'cv') {
    title = 'Curriculum Vitae'
  } else if (to.params.menu === 'bio') {
    title = 'Bio'
  } else if (to.params.menu === 'me') {
    title = 'About'
  }
  if (title) {
    document.title = `${title} - Guillaume Vieira`;
  } else {
    document.title = 'Guillaume Vieira';
  }
});

export default router
