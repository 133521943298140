import axios from 'axios';
import app from '../main';
//import store from './store';

const BASE_URL = 'https://guillaumevieira.com/api/';

const httpAxios = axios.create({
  baseURL: BASE_URL,
  //timeout: 1000,
  /*headers: {
    //'Ilm-Token': this.$store.getToken(),
    'content-type': 'application/json',
  },*/
});

httpAxios.interceptors.request.use(config => {
  app.$Progress.start(); // for every request start the progress
  return config;
}, error => {
  app.$Progress.finish();
  return Promise.reject(error);
});

httpAxios.interceptors.response.use(response => {
  app.$Progress.finish(); // finish when a response is received
  return response;
});

/*
* The interceptor here ensures that we check for the token every time an ajax request is made
*/
/*
httpAxios.interceptors.request.use(
  (config) => {
    const auth = store.getters.getAuth;

    if (auth && auth.token !== null && auth.token !== '') {
      config.headers['Ilm-Token'] = auth.token;
    }

    //config.headers['Content-Type'] = 'application/json';

    return config;
  },
  (error) => {
    console.error(`http-common.js: Error - ${error}`);
    return Promise.reject(error);
  },
);*/

export default httpAxios;
