<template>
  <div class="menu-note">
    <div class="menu-note__list">
      <Note
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        :selected="item.id === selectedId"
        :onSelected="onSelected"
        :index="sectionIndex(index)"
        :topBarHidden="topBarClass(index)"
        :bottomBarHidden="bottomBarClass(index)" />
    </div>
  </div>
</template>

<script>
import Note from '@/components/menu/Note.vue';

export default {
  name: 'MenuNote',
  components: {
    Note,
  },

  props: {
    items: Array,
    selectedId: String,
    onSelected: Function,
  },

  data() {
    return {
      currentSelectedId: this.selectedId,
    };
  },


  // Computed ********************************
  computed: {
  },

  watch: {
    selectedId (val) {
      this.currentSelectedId = val;
    }
  },

  methods: {
    sectionIndex(index) {
      const realIndex = this.items.length - 1 - index;
      return realIndex % 7;
    },
    topBarClass(index) {
      const realIndex = this.items.length - 1 - index;
      const visible = (realIndex === 0 || realIndex % 6 !== 0 || realIndex === (this.items.length - 1));
      return !visible;
    },
    bottomBarClass(index) {
      const realIndex = this.items.length - 1 - index;
      const visible = (realIndex > 0 && realIndex % 7 !== 0);
      return !visible
    },
  },
}
</script>

<style lang="scss" scoped>

.menu-note {
  display: flex;
  justify-content: center;

  &__list {
  }
}

</style>