import md5 from 'md5';
import google from './google';

export default {

  init(router) {
    // Google Analytics
    google.init(router);
    return this;
  },

  disableTracking() {
    google.optOut();
  },

  enableTracking() {
    google.optIn();
  },

  /**
   * Identify a user with a unique ID instead of a Mixpanel randomly generated distinct_id.
   * If the method is never called, then unique visitors will be identified by a UUID generated
   * the first time they visit the site.
   * @param {*} uniqueId
   */
  identify(uniqueId) {
    if (uniqueId && uniqueId !== '') {
      const userKey = md5(uniqueId);
      google.identify(userKey);
    }
  },

  /**
   * Track an event. This is the most important and frequently used Mixpanel function.
   * @param {*} eventName
   * @param {*} properties
   */
  track(eventName, properties) {
    google.track(eventName, properties);
  },

};
