<template>
  <div class="triangle">
    <div
      :class="itemClasses"
      v-tooltip="tooltip">
      <div class="triangle__item__bottom" />
      <div class="triangle__item__right" />
      <div class="triangle__item__left" :style="buttonCssVars" />

      <router-link
        :to="{ name: 'Project', params: { menu: item.menu_name.toLowerCase(), project_id: permalink, projectName: item.name, projectColor: item.color }}"
        class="triangle__link"
        @mousedown.native="onClick">
      </router-link>
    </div>
    <router-link
      v-if="showButtonText"
      :to="{ name: 'Project', params: { menu: item.menu_name.toLowerCase(), project_id: permalink, projectName: item.name, projectColor: item.color }}"
      class="triangle__link_text"
      @mousedown.native="onClick">
      <span :class="textClasses">{{ item.name }}</span>
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'Triangle',
  props: {
    item: Object,
    index: Number,
    selected: {
      type: Boolean,
      default: false,
    },
    showButtonText: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isSelected: this.selected,
    };
  },

  // Computed ********************************
  computed: {
    permalink() {
      if (this.item.name) {
        const permalink = this.item.name.toLowerCase().replace(/[^\w\s']|_/g, "").replace(/\s+/g, "-");
        return `${permalink}-${this.item.id}`;
      }
      return this.item.id;
    },
    buttonCssVars() {
      return {
        '--color': this.item.color,
      };
    },
    directionUp() {
      return this.item.direction === '1';
    },
    directionDown() {
      return this.item.direction === '0';
    },
    itemClasses() {
      const classes = {};
      classes['triangle__item'] = true;
      classes['triangle__item--up'] = this.directionUp;
      classes['triangle__item--down'] = this.directionDown;
      classes['triangle__item--selected'] = this.isSelected || this.$store.getters.isVisited(this.item.id);
      return classes;
    },
    textClasses() {
      const classes = {
        'triangle__link_text__text': true,
      }
      if (this.selected) {
        classes['triangle__link_text__text--selected'] = true;
      }
      return classes;
    },
    tooltip() {
      const tooltipContent = this.tooltipContent();
      tooltipContent.content = this.item.name;
      return tooltipContent;
    },
  },

  watch: {
    selected (val) {
      this.isSelected = val;
    }
  },

  methods: {
    tooltipContent() {
      return {
        content: '',
        offset: this.$constants.TOOLTIP_OFFSET,
        placement: 'right',
        hideOnTargetClick: true,
        delay: { show: 200, hide: 0 },
        classes: '',
      };
    },

    onClick(event) {
      this.$root.clickPosX = event.clientX;
      this.$root.clickPosY = event.clientY;
    },
  },
}
</script>

<style lang="scss" scoped>

$triangleWidth: 50px;
$triangleHeight: 44px;
$triangleBorder: 2px;

.triangle {
  margin: 10px 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  user-select: none;
  //cursor: pointer;

  &__item {
    position: relative;
    width: $triangleWidth;
    height: $triangleHeight;
    overflow: hidden;

    &--down {
      transform: rotate(180deg);
    }

    &__left {
      position: absolute;
      left: 0;
      bottom: 0;
      width: $triangleWidth;
      //height: $triangleBorder;
      height: 0;
      background: $theme-color-primary;
      transform-origin: 0% 0%;
      transform: rotate(-60deg);

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 10px;
        background-color: $theme-color-primary;
        transform-origin: 0% 0%;
        transform: skewX(-30deg);
        border-radius: 2px;
        transition: height .2s ease-in-out 0s, width .2s ease-in-out 0s, background-color .2s ease-in-out 0s, width .2s ease-in-out 0s;
      }
    }
    &__right {
      position: absolute;
      right: 0;
      bottom: 0;
      width: $triangleWidth;
      //height: $triangleBorder;
      height: 0;
      background: $theme-color-primary;
      transform-origin: 100% 0%;
      transform: rotate(60deg);

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: $triangleBorder;
        background-color: $theme-color-primary;
        transform-origin: 0% 0%;
        transform: skewX(30deg);
        border-radius: 2px;
      }
    }
    &__bottom {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      //height: $triangleBorder;
      height: 0;
      background: $theme-color-primary;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 2px;
        right: 2px;
        height: $triangleBorder;
        background-color: $theme-color-primary;
        transform-origin: 50% 0%;
        transform: skewX(30deg);
      }
    }

    @media (hover) {
      &:hover &__left:after {
        height: 43px;
        background-color: var(--color);
        border-radius: 1px;
      }
    }
    &--selected &__left:after {
      height: 43px;
      background-color: var(--color);
      border-radius: 1px;
    }
  }

  &__link {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

  }
  &__link_text {
    position: relative;
    display: block;
    //width: 100%;
    //height: 100%;
    box-sizing: border-box;

    &__text {
      position: absolute;
      left: 20px;
      top: 8px;
      right: $content-padding;
      text-align: left;
      white-space: nowrap;
      color: $theme-color-primary;
      font-family: $base-font-family;
      font-weight: 600;
      font-size: 22px;

      &--selected {
        opacity: 0.4;
      }
    }
  }

}

</style>