<template>
  <div class="error_404">
    <h1 class="error_404__title">
      404
    </h1>
    <router-link
      :to="{ name: 'Home' }"
      class="error_404__logo">
      <img
        class="error_404__logo__img"
        src="@/assets/logo.svg" />
    </router-link>
    <h4 class="error_404__message">
      Nothing to see here...
    </h4>
  </div>
</template>

<script>

export default {
  name: '404',
  components: {
  },
  props: {
  },

  // Mounted ********************************
  mounted() {
    this.$analytics.track('Visit 404');
  },

  // Computed ********************************
  computed: {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

.error_404 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    color: $theme-color-primary;
  }

  &__logo {
    &__img {
      width: 100px;
      height: 100px;
    }
  }

  &__message {
    color: $theme-color-primary;
  }
}

</style>
