import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menu: {
      about: null,
      bio: null,
      cv: null,
      notes: null,
      triangles: null,
    },
    projects: {},
    visited: {},
  },
  mutations: {
    SET_MENU_ABOUT (state, value) {
      state.menu.about = value;
    },
    SET_MENU_BIO (state, value) {
      state.menu.bio = value;
    },
    SET_MENU_CV (state, value) {
      state.menu.cv = value;
    },
    SET_MENU_NOTES (state, value) {
      state.menu.notes = value;
    },
    SET_MENU_TRIANGLES (state, value) {
      state.menu.triangles = value;
    },
    SET_PROJECT (state, obj) {
      state.projects[obj.key] = obj.value;
    },
    SET_VISITED (state, obj) {
      state.visited[obj.key] = obj.value;
    },
  },
  actions: {
    setMenuAbout (context, value) {
      context.commit('SET_MENU_ABOUT', value);
    },
    setMenuBio (context, value) {
      context.commit('SET_MENU_BIO', value);
    },
    setMenuCV (context, value) {
      context.commit('SET_MENU_CV', value);
    },
    setMenuNotes (context, value) {
      context.commit('SET_MENU_NOTES', value);
    },
    setMenuTriangles (context, value) {
      context.commit('SET_MENU_TRIANGLES', value);
    },
    setProject (context, obj) {
      context.commit('SET_PROJECT', obj);
    },
    setVisited (context, obj) {
      context.commit('SET_VISITED', obj);
    },
  },
  getters: {
    menu: state => {
      return state.menu;
    },
    project: (state) => (key) => {
      return state.projects[key];
    },
    isVisited: state => (key) => {
      return state.visited[key] ?? false;
    },
  }
})
