<template>
  <div
    class="project-content">
    <div ref="slider" class="swiper-container" :style="swiperContainerStyle">
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in projectItems"
          :key="index"
          class="swiper-slide"
          :style="slideStyle">
          <div
            v-if="item.type === $constants.PROJECT_TYPE_IMAGE"
            class="image-container"
            :style="captionStyle">
            <img
              :src="`https://guillaumevieira.com/assets/${item.image_src}`"
              :style="imageStyle(item)"
              class="image-container__image"
              :alt="`${projectName} - Photo #${index}`"/>
            <div
              v-if="item.image_caption && item.image_caption.length > 0"
              class="image-container__caption-container"
              :style="captionStyle">
              <p class="image-container__caption-container__text" v-html="currentCaption(item)" />
            </div>
          </div>
          <div
            v-else-if="item.type === $constants.PROJECT_TYPE_TEXT"
            class="text-container">
            <TextFade
              :current-text="currentTextItem(item)"/>
          </div>
          <VideoFile
            v-else-if="item.type === $constants.PROJECT_TYPE_VIDEO"
            :height="`${height}px`"
            :width="`${width}px`"
            :src="`https://guillaumevieira.com/assets/${item.image_src}`"/>
        </div>
      </div>
    </div>
    <div v-if="showFinalPeriod" class="project-content__period" />
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
//import ImageFade from '@/components/utils/ImageFade.vue';
import TextFade from '@/components/utils/TextFade.vue';
import VideoFile from '@/components/utils/VideoFile.vue';

export default {
  name: 'ProjectContentResponsive',
  components: {
    //ImageFade,
    TextFade,
    VideoFile,
    //Flicking,
  },
  props: {
    items: Array,
    projectName: String,
    width: Number,
    height: Number,
    onUpdate: Function,
  },
  data() {
    return {
      projectItems: [],
      //currentIndex: 0,
      initialIndex: null,

      imgWidth: 0,
      imgHeight: 0,
      updateTimeout: null,
      slider: null,
      swipeOptions: {
        effect: this.$root.isMobileWidth === true ? 'slide' : 'fade',
        direction: 'horizontal',
        autoHeight: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        loop: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        iOSEdgeSwipeDetection: true,
        threshold: 10,
        //freeModeSticky: true,
        //freeMode: true,
        preloadImages: true,
      },
    };
  },

  created() {
    this.initialIndex = this.$route.query.p;
  },

  mounted() {
    this.swipeOptions.realIndex = this.initialIndex;
    this.slider = new Swiper(this.$refs.slider, this.swipeOptions);
    this.slider.on('slideChange', () => {
      window.history.replaceState(null, '', `?p=${this.currentIndex}`);
      if (this.onUpdate) {
        this.onUpdate(this.currentIndex, this.currentItem, this.projectItems);
      }
    });
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    this.slider.destroy();
  },

  // Destroyed ********************************
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  computed: {
    currentIndex() {
      return this.slider.activeIndex;
    },
    currentItem() {
      if (this.currentIndex >= 0 && this.currentIndex < this.projectItems.length) {
        return this.projectItems[this.currentIndex];
      }
      return null;
    },
    currentText() {
      if (this.projectItems[this.currentIndex].text) {
        const content = this.projectItems[this.currentIndex].text.replaceAll('&lt;','<').replaceAll('&gt;','>');
        //console.log(content);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(content, 'text/html');
        return xmlDoc.body.innerHTML;
        //return xmlDoc.documentElement.textContent;
      }
      return null;
    },
    captionStyle() {
      if (this.width) {
        return {
          'width': `${this.width}px`,
        };
      }
      return {
        //'padding-bottom': hasCaption ? '10px' : '20px'
      };
    },
    swiperContainerStyle() {
      if (this.width) {
        return {
          'width': `${this.width}px`,
        };
      }
      return {
        //'padding-bottom': hasCaption ? '10px' : '20px'
      };
    },
    projectContentStyle() {
      if (!this.currentItem) return {};
      const hasCaption = this.currentItem.image_caption && this.currentItem.image_caption.length > 0;
      return {
        'project-content': true,
        'project-content--small-margin': hasCaption,
      };
    },
    slideStyle() {
      return {
        'height': `${this.height}px`
      }
    },
    showFinalPeriod() {
      //return false;
      if (!this.$root.isMobile || !this.items || !this.projectItems) return false;
      return this.currentIndex === this.projectItems.length - 1;
    },
  },

  watch: {
    items(val) {
      //this.currentIndex = 0;
      this.projectItems = val;
      this.update();
      this.slider.slideTo(0, 0);
      this.$nextTick(() => {
        this.slider.update();
        if (this.initialIndex && this.initialIndex < this.projectItems.length) {
          this.slider.slideTo(this.initialIndex, 0, true, true);
          this.initialIndex = null;
        }
      });
    },
  },

  methods: {
    onSwiper(s) {
      this.swiper = s;
    },
    prev() {
      if (this.currentIndex > 0) {
        this.slider.slidePrev();
      }
    },
    next() {
      if (this.currentIndex < this.projectItems.length - 1) {
        this.slider.slideNext();
      } else {
        this.slider.slideTo(0, 0, true, true);
      }
    },

    update() {
      if (this.currentItem) {
        this.imgWidth = this.currentItem.image_width;
        this.imgHeight = this.currentItem.image_height;
      }

      if (this.onUpdate) {
        this.onUpdate(this.currentIndex, this.currentItem, this.projectItems);
      }
    },

    imageStyle(item) {
      const hasCaption = item.image_caption && item.image_caption.length > 0;
      if (this.width && this.height) {
        let height = this.height;
        /*if (this.imgWidth && this.imgHeight) {
          const ratio = this.imgHeight / this.imgWidth;
          height = ratio * this.width;
        }*/
        return {
          //'align-self': 'center'
          'width': `${this.width}px`,
          'height': hasCaption ? `${this.height-200}px` : `${height}px`,
          'padding-bottom': hasCaption ? '10px' : '100px',
          //'height': hasCaption ? `${height}px` : `${height}px`,
          //'padding-bottom': hasCaption ? '0' : '0',
        };
      }
      return {
        //'padding-bottom': hasCaption ? '10px' : '20px'
      };
    },
    currentCaption(item) {
      if (item.image_caption && item.image_caption.length > 0) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(item.image_caption.replaceAll('&lt;','<').replaceAll('&gt;','>'), 'text/html');
        return xmlDoc.body.innerHTML;
      }
      return null;
    },
    currentTextItem(item) {
      if (item.text) {
        const content = item.text.replaceAll('&lt;','<').replaceAll('&gt;','>');
        //console.log(content);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(content, 'text/html');
        return xmlDoc.body.innerHTML;
        //return xmlDoc.documentElement.textContent;
      }
      return null;
    },
    
    onSlideChange() {

    },
    onChange(obj) {
      this.currentIndex = obj.index;
      if (this.onUpdate) {
        this.onUpdate(this.currentIndex, this.currentItem, this.projectItems);
      }
    },
    handleResize() {
      if (this.updateTimeout) {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = null;
      }
      this.updateTimeout = setTimeout(() => {
        if (this.slider) {
          this.slider.update();
        }
      }, 300);
    },
  },
}
</script>

<style lang="scss" scoped>
$content-padding-left: $navbar-logo-size + ($navbar-padding * 2);

.swiper-wrapper {
  //height: 100%;
  display: flex;
  //overflow-x: scroll;
}
.swiper-slide {
  //flex-shrink: 0;
  box-sizing: border-box;
  background-color: white;
  overflow-y: auto;
}

.flicking-viewport {
  width: 100%;
  //height: 100%;
}

.project-content {
  display: flex;
  //padding-right: $content-padding-left; //126px;
  //margin-bottom: 40px; // 60px

  @media screen and (max-width: $mobile-screen-width), screen and (max-height: $mobile-screen-width) {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;

    &--small-margin {
      padding-bottom: 10px !important;
    }
  }

  &__period {
    position: absolute;
    bottom: 50%;
    margin-bottom: 40px;
    transform: translate(0%, -50%);
    right: 6px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: $theme-color-primary;
    //z-index: 999;
  }

  &__img {
    /*max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    object-fit: contain;*/
    padding-right: $content-padding-left;
    @media screen and (max-width: $mobile-screen-width), screen and (max-height: $mobile-screen-width) {
      padding-right: 0;
    }
  }

  &__text {
    width: 100%;
    height: fit-content;
    max-width: 600px + $content-padding-left;
    font-family: 'Work Sans', sans-serif !important;
    font-size: 14px !important;
    line-height: 16px !important;
    margin-top: 20px;
    padding-left: $navbar-logo-size - $navbar-padding + 14px;
    padding-bottom: 60px;

    @media screen and (max-width: $mobile-screen-width), screen and (max-height: $mobile-screen-width) {
      max-width: 100%;
      padding-left: 26px;
      padding-right: 26px;
    }

    &::v-deep * {
      font-family: 'Work Sans', sans-serif !important;
      font-size: 14px !important;
      line-height: 16px !important;
    }
  }
}

.video-container {
  padding-right: $content-padding-left;
  @media screen and (max-width: $mobile-screen-width), screen and (max-height: $mobile-screen-width) {
    padding-right: 0;
  }
}

.image-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: inherit;
  position: relative;

  &__image {
    //max-width: 100%;
    //   max-height: 100%;
    //   margin: 0 auto;
    object-fit: contain;
    flex-grow: 1;
    padding-right: $content-padding-left;
    
    @media screen and (max-width: $mobile-screen-width), screen and (max-height: $mobile-screen-width) {
      padding-right: 0;
    }
  }

  &__caption-container {
    height: 200px;
    padding: 10px;
    text-align: left;
    padding-right: 100px;
    display: flex;
    justify-content: flex-end;

    &__text {
      max-width: 570px;
      font-size: 13px;
    }

    @media screen and (max-width: $mobile-screen-width), screen and (max-height: $mobile-screen-width) {
      text-align: left;
      padding-right: 10px;
      justify-content: start;

      &__text {
        max-width: 100%;
      }
    }
  }
}

</style>
