<template>
  <transition name="fade-fast">
    <div
      v-show="loaded"
      class="text"
      v-html="content"/>
  </transition>
</template>

<script>
export default {
  name: 'TextFade',
  props: {
    currentText: String,
  },
  data() {
    return {
      content: null,
      loaded: false,
    };
  },
  mounted() {
    if (this.currentText) {
      setTimeout(() => {
        this.content = this.currentText;
        this.loaded = true;
      }, 300);
    }
  },
  watch: {
    currentText(val) {
      this.loaded = false;
      if (!val) {
        return;
      }
      setTimeout(() => {
        this.content = this.currentText;
        this.loaded = true;
      }, 300);  
    },
  },
  computed: {
  },
  methods: {
  },
};
</script>


<style lang="scss" scoped>

.text {
  width: 100%;
  height: fit-content;
  //max-width: 600px + $content-padding-left;
  max-width: 616px;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 14px !important;
  line-height: 16px !important;
  margin-top: 20px;
  padding-left: $navbar-logo-size - $navbar-padding + 14px;
  padding-bottom: 60px;

  @media screen and (max-width: $mobile-screen-width) {
    max-width: 100%;
    padding-left: 26px;
    padding-right: 26px;
    font-size: 16px !important;
    line-height: 20px !important;
  }

  &::v-deep * {
    font-family: 'Work Sans', sans-serif !important;
    font-size: 14px !important;
    line-height: 16px !important;

    @media screen and (max-width: $mobile-screen-width) {
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }
}
</style>