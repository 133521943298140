<template>
    <div class="root">
        <div class="root__mobile_header">
            <Socials class="root__mobile_header__socials" :instagram_link="instagramLink" :email="emailAddress" />
        </div>
        <div class="root__title_container">
            <div class="root__circle" />
            <div class="root__triangle">
                <div class="root__triangle__item root__triangle__item--down">
                    <div class="root__triangle__item__bottom" />
                    <div class="root__triangle__item__right" />
                    <div class="root__triangle__item__left" />
                </div>
            </div>
            <div class="root__title_container__content">
                <h1 class="root__title_container__content__title">
                    {{ mainTitle }}
                </h1>
                <Socials class="root__title_container__content__socials" :instagram_link="instagramLink"
                    :email="emailAddress" />
                <div class="root__square" />
            </div>
        </div>
        <div class="root__options_container">
            <div
                :class="`root__options_container__mobile ${openMenuIndex === 0 ? 'root__options_container__mobile--open' : ''}`">
                <div class="root__options_container__mobile__content">
                    <div class="root__options_container__mobile__content__header" @click="() => onTitlePressed(0)">
                        <img class="root__options_container__mobile__content__header__drawer_icon"
                            :src="navArrowFile" />
                        <h3
                            class="root__options_container__row__title root__options_container__mobile__content__header__title">
                            {{ artTitle }}</h3>
                    </div>
                    <p class="root__options_container__row__description root__options_container__mobile__content__description"
                        v-html="artText" />
                </div>
                <a href="https://art.guillaumevieira.com" target="_blank"
                    class="root__options_container__mobile__arrow_link"><img
                        class="root__options_container__mobile__arrow_link__icon" :src="navArrowFile" /><span>go to
                        site</span></a>
            </div>
            <div
                :class="`root__options_container__mobile ${openMenuIndex === 1 ? 'root__options_container__mobile--open' : ''}`">
                <div class="root__options_container__mobile__content">
                    <div class="root__options_container__mobile__content__header" @click="() => onTitlePressed(1)">
                        <img class="root__options_container__mobile__content__header__drawer_icon"
                            :src="navArrowFile" />
                        <h3
                            class="root__options_container__row__title root__options_container__mobile__content__header__title">
                            {{ commissionTitle }}</h3>
                    </div>
                    <p class="root__options_container__row__description root__options_container__mobile__content__description"
                        v-html="commissionText" />
                </div>
                <a href="https://studio.guillaumevieira.com" target="_blank"
                    class="root__options_container__mobile__arrow_link"><img
                        class="root__options_container__mobile__arrow_link__icon" :src="navArrowFile" /><span>go to
                        site</span></a>
            </div>


            <div class="root__options_container__row">
                <h3 class="root__options_container__row__title">{{ artTitle }}</h3>
                <p class="root__options_container__row__description" v-html="artText" />
                <a href="https://art.guillaumevieira.com" target="_blank">artistic practice<img
                        class="root__options_container__row__arrow_icon" :src="navArrowFile" /></a>
            </div>
            <div class="root__options_container__row">
                <h3 class="root__options_container__row__title">{{ commissionTitle }}</h3>
                <p class="root__options_container__row__description" v-html="commissionText" />
                <a href="https://studio.guillaumevieira.com" target="_blank">commercial photography<img
                        class="root__options_container__row__arrow_icon" :src="navArrowFile" /></a>
            </div>
        </div>
    </div>
</template>

<script>
import Socials from "@/components/root-home/Socials.vue";
import navArrowIcon from "@/assets/nav_arrow.svg";

export default {
    name: "Root",
    components: {
        Socials
    },
    data() {
        return {
            openMenuIndex: -1,
            homeContent: null
        };
    },
    // Created ********************************
    created() {
        this.requestContent();
    },

    computed: {
        mainTitle() {
            if (!this.homeContent?.main_title) return null;
            return this.homeContent?.main_title;
        },
        instagramLink() {
            if (!this.homeContent?.instagram_link) return null;
            return this.homeContent?.instagram_link;
        },
        emailAddress() {
            if (!this.homeContent?.email) return null;
            return this.homeContent?.email;
        },
        artTitle() {
            if (!this.homeContent?.art_title) return null;
            return this.homeContent?.art_title;
        },
        artText() {
            if (!this.homeContent?.art_text || this.homeContent.art_text.length === 0) return null;
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(
                this.homeContent.art_text.replaceAll("&lt;", "<").replaceAll("&gt;", ">"),
                "text/html"
            );
            return xmlDoc.body.innerHTML;
        },
        commissionTitle() {
            if (!this.homeContent?.commission_title) return null;
            return this.homeContent?.commission_title;
        },
        commissionText() {
            if (!this.homeContent?.commission_text || this.homeContent.commission_text.length === 0) return null;
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(
                this.homeContent.commission_text.replaceAll("&lt;", "<").replaceAll("&gt;", ">"),
                "text/html"
            );
            return xmlDoc.body.innerHTML;
        },

        navArrowFile() {
            return navArrowIcon;
        },
        isMobileWidth() {
            return this.$root.isMobileWidth === true;
        }
        // this.$root.isMobileWidth === true
    },
    methods: {
        onTitlePressed(index) {
            this.openMenuIndex = this.openMenuIndex === index ? -1 : index;
        },
        requestContent() {
            this.$http.getHome(
                (response) => {
                    console.log(response);
                    this.homeContent = response;
                },
                (error) => {
                    console.log(error);
                    this.homeContent = null;
                    window.open('https://art.guillaumevieira.com', '_self');
                }
            );
        },
    },
    /*
        beforeRouteLeave (to, from, next) {
          if (['About', 'Project'].includes(to.name) && this.$utils.isTouchDevice()) {
            next(false);
          } else {
            next();
          }
        }*/
};
</script>

<style lang="scss" scoped>
$triangleWidth: 60px;
$triangleHeight: 54px;
$triangleBorder: 1px;

$triangleWidthMobile: 40px;
$triangleHeightMobile: 34px;

.root {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100vh;

    @media screen and (max-width: $mobile-screen-width) {
        flex-direction: column;
    }

    &__mobile_header {
        display: none;
        background-color: $theme-color-primary;
        padding: 0 40px;
        align-items: center;
        height: 58px;

        @media screen and (max-width: $mobile-screen-width) {
            display: flex;
            position: sticky;
            top: 0;
            z-index: 0;
        }

        &__socials {
            text-align: left;
        }
    }

    &__title_container {
        position: relative;
        background-color: $theme-color-primary;
        flex: 0.45;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        flex-direction: column;

        @media screen and (max-width: $mobile-screen-width) {
            //position: sticky;
            //top: 0;
            z-index: -1;
            flex: initial;
            position: sticky;
            top: 58px;
            left: 0;
            right: 0;
        }

        &:after {
            content: "";
            position: absolute;
            left: 40px;
            top: 30px;
            bottom: 30px;
            border-left-color: white;
            border-left-width: 1px;
            border-left-style: dashed;
            user-select: none;

            @media screen and (max-width: $mobile-screen-width) {
                left: 30px;
                bottom: 40px;
                top: 50px;
            }
        }

        &__content {
            position: relative;
            margin: 0 14%;
            text-align: left;
            max-width: 360px;

            @media screen and (max-width: $mobile-screen-width) {
                margin-top: 130px;
                margin-bottom: 160px;
            }

            &__title {
                font-size: 2em;
                font-weight: 400;
                text-align: left;
                margin: 0;

                @media screen and (max-width: 920px) {
                    font-size: 1.6em;
                }

                @media screen and (max-width: $mobile-screen-width) {
                    font-size: 1.6em;
                }
            }

            &__socials {
                margin-top: 80px;

                @media screen and (max-width: $mobile-screen-width) {
                    display: none;
                }

                // &::v-deep a {
                //     color: white;
                //     font-size: 0.8em;
                //     margin-left: 16px;

                //     &:first-child {
                //         margin-left: 0;
                //     }
                // }
            }
        }
    }

    &__options_container {
        //width: 70%;
        position: relative;
        background-color: $theme-color-backdrop;
        flex: 0.65;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 10;

        @media screen and (max-width: $mobile-screen-width) {
            flex: 1;
            //margin-top: 400px;
        }

        &__row {
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: flex-start;
            justify-content: center;
            max-width: 500px;
            width: 100%;
            padding: 0 40px;
            color: $theme-color-primary;

            @media screen and (max-width: $mobile-screen-width) {
                display: none;
            }

            &__title {
                font-size: 1.2em;
                font-weight: 500;
                text-align: left;
                margin: 0;
            }

            &__description {
                margin: 0;
                font-size: 1em;
                margin-top: 10px;
                text-align: left;
            }

            &::v-deep a {
                font-size: 0.8em;
                margin-right: -40px;
                margin-top: 20px;
                align-self: flex-end;
                padding: 10px 0;
                align-items: center;
                display: flex;
            }

            &__arrow_icon {
                width: 26px;
                height: 26px;
                margin-left: 16px;
                transform: rotate(180deg);
            }
        }

        &__mobile {
            margin: 8px 0;
            padding: 4px 24px;
            color: $theme-color-primary;
            display: none;
            flex-direction: row;
            text-align: left;
            width: 100%;

            &:first-child {
                margin-top: 40px;
            }

            &:last-child {
                margin-bottom: 40px;
            }

            @media screen and (max-width: $mobile-screen-width) {
                display: flex;
            }

            &__content {
                flex-direction: column;
                flex-grow: 1;
                flex-shrink: 1;
                border-bottom: 1px solid $theme-color-primary;
                margin-bottom: 16px;

                &__header {
                    display: flex;
                    flex-direction: row;
                    padding-bottom: 16px;
                    cursor: pointer;

                    &__drawer_icon {
                        width: 14px;
                        height: 14px;
                        margin: 0;
                        margin-top: 4px;
                        margin-right: 10px;
                        transform: rotate(-90deg);
                        transition-duration: 0.2s;
                        transition-property: transform;
                    }

                    &__title {
                        text-align: left;
                        flex-grow: 1;
                        flex-shrink: 1;
                    }
                }

                &__description {
                    display: none;
                    margin: 0;
                    margin-bottom: 10px;
                }
            }

            &--open &__content {
                &__description {
                    display: block;
                }

                &__header {
                    &__drawer_icon {
                        transform: rotate(90deg);
                    }
                }
            }

            &__arrow_link {
                //border: 1px solid $theme-color-primary;
                border-radius: 36px;
                align-self: flex-start;
                flex: 1;
                align-items: center;
                justify-content: center;
                margin-left: 16px;
                padding: 10px 0;
                margin-top: -8px;
                flex-grow: 0;
                flex-shrink: 0;
                text-align: center;

                &__icon {
                    width: 22px;
                    height: 22px;
                    margin: 0 10px;
                    transform: rotate(135deg);
                    margin-bottom: 6px;
                }

                & span {
                    font-size: 12px;
                    display: none;
                }
            }

            &--open &__arrow_link {
                //outline: 1px solid $theme-color-primary;
                box-shadow: 0px 0px 0px 1px $theme-color-primary inset;

                & span {
                    display: initial;
                }
            }
        }
    }

    &__circle {
        position: absolute;
        left: 30px;
        top: 14%;
        width: 66px;
        height: 66px;
        border-radius: 33px;
        border: 1px solid white;
        user-select: none;

        @media screen and (max-width: $mobile-screen-width) {
            left: 4px;
            top: 30px;
            width: 44px;
            height: 44px;
            border-radius: 22px;
        }
    }

    &__square {
        position: absolute;
        bottom: -100px;
        left: 100px;
        width: 58px;
        height: 58px;
        border: 1px solid white;
        border-radius: 1px;
        transform: rotate(-45deg);
        box-sizing: content-box;
        user-select: none;

        @media screen and (max-width: $mobile-screen-width) {
            bottom: -120px;
            left: initial;
            right: 20px;
            width: 36px;
            height: 36px;
        }
    }

    &__triangle {
        position: absolute;
        right: 100px;
        top: 10%;
        user-select: none;

        @media screen and (max-width: $mobile-screen-width) {
            right: 40px;
            top: -0;
        }

        &__item {
            position: relative;
            width: $triangleWidth;
            height: $triangleHeight;
            overflow: hidden;

            @media screen and (max-width: $mobile-screen-width) {
                width: $triangleWidthMobile;
                height: $triangleHeightMobile;
            }

            &--down {
                transform: rotate(180deg);
            }

            &__left {
                position: absolute;
                left: 0;
                bottom: 0;
                width: $triangleWidth;
                height: 0;
                background: white;
                transform-origin: 0% 0%;
                transform: rotate(-60deg);

                @media screen and (max-width: $mobile-screen-width) {
                    width: $triangleWidthMobile;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: $triangleBorder;
                    background-color: white;
                    transform-origin: 0% 0%;
                    transform: skewX(-30deg);
                    border-radius: 2px;
                }
            }

            &__right {
                position: absolute;
                right: 0;
                bottom: 0;
                width: $triangleWidth;
                height: 0;
                background: white;
                transform-origin: 100% 0%;
                transform: rotate(60deg);

                @media screen and (max-width: $mobile-screen-width) {
                    width: $triangleWidthMobile;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: $triangleBorder;
                    background-color: white;
                    transform-origin: 0% 0%;
                    transform: skewX(30deg);
                    border-radius: 2px;
                }
            }

            &__bottom {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 0;
                background: white;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 1px;
                    right: 1px;
                    height: $triangleBorder;
                    background-color: white;
                    transform-origin: 50% 0%;
                    //transform: skewX(30deg);
                }
            }
        }
    }

    // @media screen and (max-width: $mobile-screen-width) {
    //   flex-direction: column;
    //   align-items: start;
    // }
}
</style>
