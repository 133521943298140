<template>
  <div class="home">
    <Menu />
  </div>
</template>

<script>
import Menu from '@/components/menu/Menu.vue'

export default {
  name: 'Home',
  components: {
    Menu,
  },
  /*
  beforeRouteLeave (to, from, next) {
    if (['About', 'Project'].includes(to.name) && this.$utils.isTouchDevice()) {
      next(false);
    } else {
      next();
    }
  }*/
}
</script>
