<template>
  <div class="project">

    <div ref="navbar" class="project__navbar">
      <div class="project__navbar__inner">
        <div class="project__navbar__logo">
          <router-link :to="{ name: 'Home' }" title="Home - Guillaume Vieira" class="project__navbar__logo__link">
            <img v-if="logoFile" class="project__navbar__logo__link__img" :src="logoFile" />
          </router-link>
        </div>
        <div class="project__navbar__info">
          <div class="project__navbar__info__title_container">
            <h1 class="project__navbar__info__title_container__title">{{ title }}</h1>
          </div>
        </div>
        <!-- <div v-if="$utils.isTouchDevice()" :class="menuButtonClasses" @click="projectMenuPressed">
          <img :src="menuImageFile" class="project__navbar__menuBtn__img" />
        </div> -->
      </div>
    </div>

    <div class="project__main" :style="mainWidthStyle">

      <div ref="menubar" class="project__main__menu">
        <MenuNote v-if="menu === 'art'" class="project__main__menu__component" :items="menuItems"
          :selected-id="projectId" />
        <MenuTriangle v-else-if="menu === 'collection'" class="project__main__menu__component" :showStackList="false"
          :items="menuItems" :selected-id="projectId" />
      </div>

      <div class="project__main__content" @click="toggleFullscreen">
        <Content ref="projectContent" :project-name="title" :items="projectData" :width="contentWidth"
          :height="contentHeight" :on-update="onContentUpdate" />
      </div>
    </div>
    <transition name="fade-fast">
      <div ref="projectCaptionContainer" v-show="caption" class="project__caption_container">
        <div v-if="caption" class="project__caption_container__caption">
          <p ref="captionText" class="project__caption_container__text" v-html="caption" />
        </div>
        <span v-if="caption && captionHasEllipsis" class="project__caption_container__read_more"
          @click="onReadMorePressed">Read more</span>
      </div>
    </transition>

    <!--div v-if="showArrowUp" class="project__button_up" @click="upPressed"><img v-if="navArrowFile && !$utils.isTouchDevice()" class="project__button_up__icon" :src="navArrowFile" /></div-->
    <!--div v-if="showArrowDown" class="project__button_down" @click="downPressed"><img v-if="navArrowFile && !$utils.isTouchDevice()" class="project__button_down__icon" :src="navArrowFile" /></div-->
    <div v-if="showArrowLeft && !$utils.isTouchDevice()" class="project__button_left" @click="prevPressed"><img
        v-if="navArrowFile && !$utils.isTouchDevice()" class="project__button_left__icon" :src="navArrowFile" /></div>
    <div v-if="showArrowRight && !$utils.isTouchDevice()" class="project__button_right" @click="nextPressed"><img
        v-if="navArrowFile && !$utils.isTouchDevice()" class="project__button_right__icon" :src="navArrowFile" /></div>

    <!--div class="project__button_menu">
      <img :src="menuImageFile"  />
    </div-->

    <InfoModal v-if="captionModalShowing" :contentText="caption" @closed="onHideModal" />

    <ModalNote v-if="menuModalNoteShowing" :items="menuItems" :selectedId="projectId" @closed="onHideMenuModal" />
    <ModalTriangle v-if="menuModalTriangleShowing" :items="menuItems" :selectedId="projectId"
      @closed="onHideMenuModal" />

    <transition name="fade-fast">
      <div v-if="isFullscreen" class="fullscreen" @click="closeFullscreen" @keyup.esc="closeFullscreen">
        <img :src="fullscreenImageUrl" />
      </div>
    </transition>
  </div>
</template>

<script>
// https://swiperjs.com
//import Swiper from 'swiper';
//import 'swiper/swiper-bundle.css'

import logoArt from "@/assets/logo_art.svg";
import logoCollection from "@/assets/logo_collection.svg";
import navArrowIcon from "@/assets/nav_arrow.svg";
import menuNote from "@/assets/menu-nota.svg";
import menuTriangle from "@/assets/menu-triangulos.svg";

import MenuNote from '@/components/menu/MenuNote.vue';
import MenuTriangle from '@/components/menu/MenuTriangle.vue';
//import ProjectContent from '@/components/project/ProjectContent.vue';
//import ProjectContentResponsive from '@/components/project/ProjectContentResponsive.vue';
import Content from '@/components/project/Content.vue';

import InfoModal from '@/components/utils/InfoModal.vue';
import ModalNote from '@/components/menu/ModalNote.vue'
import ModalTriangle from '@/components/menu/ModalTriangle.vue'

// https://github.com/mirari/vue-fullscreen
//import { component } from 'vue-fullscreen';

export default {
  name: 'Project',
  components: {
    MenuNote,
    MenuTriangle,
    //ProjectContent,
    //ProjectContentResponsive,
    Content,
    InfoModal,
    ModalNote,
    ModalTriangle,
    //fullscreen: component
  },

  props: {
    projectName: String,
    projectColor: String,
  },

  data() {
    return {
      menu: null,
      menuItems: [],
      projectId: null,
      projectTitle: this.projectName,
      projectIndex: -1,
      projectData: null,
      caption: null,
      captionHasEllipsis: false,
      captionModalShowing: false,
      captionLines: 4,

      lastItemType: '',
      projectContent: null,

      menuModalNoteShowing: false,
      menuModalTriangleShowing: false,

      contentWidth: 0,
      contentHeight: 0,

      showArrowUp: false,
      showArrowDown: false,

      showArrowLeft: false,
      showArrowRight: false,

      isFullscreen: false,
      fullscreenImageUrl: '',

      swiper: null,
      swipeOptions: {
        direction: 'horizontal',
        centeredSlides: true,
        effect: 'fade',
        init: false,
        spaceBetween: 0,
        grabCursor: true,
        //loop: false,
        slidesPerView: 1,
        iOSEdgeSwipeDetection: true,
        //threshold: 5,
        on: {
          //slideNextTransitionEnd: this.slideNextTransitionEnd,
          //slidePrevTransitionEnd: this.slidePrevTransitionEnd,
          //slideChange: this.onSlideChange,
        },
      },
    };
  },

  // Created ********************************
  created() {
    if (!this.$route.params.menu || this.$route.params.menu === '' || !this.$route.params.project_id) {
      this.$router.replace({ name: 'Home' });
    }

    this.menu = this.$route.params.menu?.toLowerCase();
    const arr = this.$route.params.project_id.split('-');
    if (arr.length < 2) {
      this.$router.replace({ name: 'Home' });
    }
    this.projectId = arr[arr.length - 1];
    if (isNaN(this.projectId)) {
      this.$router.replace({ name: 'Home' });
    }

    this.requestMenu();
  },
  beforeCreate() {
    const body = document.body;
    body.classList.add('project');
  },

  mounted() {
    //this.swiper = new Swiper(this.$refs.slider, this.swipeOptions);

    this.$nextTick(() => {
      this.handleResize();

      this.$store.dispatch('setVisited', {
        key: this.projectId,
        value: true,
      });
    });

    window.addEventListener('resize', this.handleResize);
    window.addEventListener('keydown', this.onKeydown);

    this.projectContent = this.$refs.projectContent;

    this.requestContent();
  },

  beforeDestroy() {
    const body = document.body;
    body.classList.remove('project');
  },

  // Destroyed ********************************
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('keydown', this.onKeydown);
  },

  // Computed ********************************
  computed: {
    title() {
      if (this.projectTitle) {
        return this.projectTitle;
      }
      return '';
    },
    logoFile() {
      if (this.menu === 'art') {
        return logoArt;
      } else if (this.menu === 'collection') {
        return logoCollection;
      }
      return null;
    },
    menuImageFile() {
      if (this.menu === 'art') {
        return menuNote;
      }
      if (this.menu === 'collection') {
        return menuTriangle;
      }
      return null;
    },
    menuButtonClasses() {
      const classes = {
        'project__navbar__menuBtn': true
      };
      if (this.menu === 'art') {
        classes['project__navbar__menuBtn--note'] = true;
      } else if (this.menu === 'collection') {
        classes['project__navbar__menuBtn--triangle'] = true;
      } else {
        classes['project__navbar__menuBtn--square'] = true;
      }
      return classes;
    },
    navArrowFile() {
      return navArrowIcon;
    },
    navArrowLeftClasses() {
      const classes = {};
      classes['project__navbar__info__navigation__arrow'] = true;
      classes['project__navbar__info__navigation__arrow--left'] = true;
      classes['project__navbar__info__navigation__arrow--disabled'] = !this.showArrowLeft;
      return classes;
    },
    navArrowRightClasses() {
      const classes = {};
      classes['project__navbar__info__navigation__arrow'] = true;
      classes['project__navbar__info__navigation__arrow--right'] = true;
      classes['project__navbar__info__navigation__arrow--disabled'] = !this.showArrowRight;
      return classes;
    },

    mainWidthStyle() {
      return {
        height: `${this.contentHeight}px`,
      };
    },
  },

  watch: {
    $route() {
      this.onHideMenuModal();
      const arr = this.$route.params.project_id.split('-');
      this.projectId = arr[arr.length - 1];
      //this.projectId = this.$route.params.project_id;
      this.projectTitle = this.projectName;
      this.requestContent();

      this.$store.dispatch('setVisited', {
        key: this.projectId,
        value: true,
      });
    },
  },


  methods: {
    /*onSnipped (newState) {
      this.captionHasEllipsis = newState.hasEllipsis;
    },*/
    onReadMorePressed() {
      this.captionModalShowing = true;
    },
    onHideModal() {
      this.captionModalShowing = false;
    },
    handleResize() {
      const navbar = this.$refs.navbar;
      const menubar = this.$refs.menubar;
      /*
      const captionContainer = this.$refs.projectCaptionContainer;
      const captionBottom = this.$utils.isTouchDevice() ? 0 : 40;

      if (this.caption) {
        if (this.$utils.isTouchDevice()) {
          this.contentHeight = window.innerHeight - navbar.clientHeight;
        } else {
          this.contentHeight = window.innerHeight - navbar.clientHeight - captionContainer.clientHeight - captionBottom;
        }
      } else {
        this.contentHeight = window.innerHeight - navbar.clientHeight;
      }*/
      this.contentHeight = window.innerHeight - navbar.clientHeight;
      if (this.$root.isMobileWidth) {
        this.contentWidth = window.innerWidth;
      } else {
        this.contentWidth = window.innerWidth - menubar.clientWidth;
      }
      //console.log(`height: ${this.contentHeight} | contentWidth: ${this.contentWidth} | navbarHeight: ${navbar.clientHeight} | window.innerHeight: ${window.innerHeight}`);
    },
    onKeydown(e) {
      if (e.key === 'ArrowRight') {
        e.preventDefault();
        this.nextPressed();
      } else if (e.key === 'ArrowLeft') {
        e.preventDefault();
        this.prevPressed();
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        this.upPressed();
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        this.downPressed();
      } else if (e.key === 'Escape' && this.isFullscreen) {
        e.preventDefault();
        this.isFullscreen = false;
      }
    },


    projectMenuPressed() {
      if (this.$utils.isTouchDevice()) {
        if (this.menu === 'art') {
          this.menuModalNoteShowing = true;
        } else if (this.menu === 'collection') {
          this.menuModalTriangleShowing = true;
        }
      }
    },
    onHideMenuModal() {
      this.menuModalNoteShowing = false;
      this.menuModalTriangleShowing = false;
    },

    prevPressed() {
      if (this.projectContent) {
        this.projectContent.prev();
      }
    },
    nextPressed() {
      if (this.projectContent) {
        this.projectContent.next();
      }
    },

    permalink(item) {
      if (item.name) {
        const permalink = item.name.toLowerCase().replace(/[^\w\s']|_/g, "").replace(/\s+/g, "-");
        return `${permalink}-${item.id}`;
      }
      return item.id;
    },

    upPressed() {
      if (this.projectIndex > 0 && this.projectIndex < this.menuItems.length) {
        this.projectIndex = this.projectIndex - 1;
        const item = this.menuItems[this.projectIndex];
        this.updateProjectIndex(false);
        this.$router.replace({ name: 'Project', params: { menu: item.menu_name.toLowerCase(), project_id: this.permalink(item), projectName: item.name, projectColor: item.color } });
      }
    },
    downPressed() {
      if (this.projectIndex >= 0 && this.projectIndex < this.menuItems.length - 1) {
        this.projectIndex = this.projectIndex + 1;
        const item = this.menuItems[this.projectIndex];
        this.updateProjectIndex(false);
        this.$router.replace({ name: 'Project', params: { menu: item.menu_name.toLowerCase(), project_id: this.permalink(item), projectName: item.name, projectColor: item.color } });
      }
    },



    onContentUpdate(index, item, array) {
      this.showArrowLeft = index > 0;
      //this.showArrowRight = index < array.length - 1;
      this.showArrowRight = array.length > 1;

      if (item && item.type === this.$constants.PROJECT_TYPE_IMAGE) {
        this.fullscreenImageUrl = `https://guillaumevieira.com/assets/${item.image_src}`;
      } else {
        this.isFullscreen = false;
        this.fullscreenImageUrl = '';
      }
    },


    requestMenu() {
      if ((this.menu === 'art' && !this.$store.getters.menu.notes)
        || (this.menu === 'collection' && !this.$store.getters.menu.triangles)) {
        const compare = (a, b) => {
          if (a.order < b.order) {
            return 1;
          }
          if (a.order > b.order) {
            return -1;
          }
          return 0
        };

        this.$http.getMenu(null, (response) => {
          if (this.menu === 'art') {
            this.menuItems = response.art.sort(compare);
          } else if (this.menu === 'collection') {
            this.menuItems = response.collection.sort(compare);
          }

          this.$store.dispatch('setMenuNotes', response.art.sort(compare));
          this.$store.dispatch('setMenuTriangles', response.collection.sort(compare));
          this.updateProjectIndex();
        }, (error) => {
          console.log(error);
          this.$router.replace({ name: 'Error' });
        });
      } else {
        if (this.menu === 'art') {
          this.menuItems = this.$store.getters.menu.notes;
        } else if (this.menu === 'collection') {
          this.menuItems = this.$store.getters.menu.triangles;
        }
        this.updateProjectIndex();
      }
    },

    requestContent() {
      const project = this.$store.getters.project(this.projectId);
      if (project) {
        this.projectData = project;
      } else {
        this.projectData = [];
        const projectId = this.projectId;
        this.$http.getProjectItems(projectId, (response) => {
          this.projectData = response;
          this.$store.dispatch('setProject', {
            key: projectId,
            value: response,
          });
        }, (error) => {
          console.log(error);
          this.$router.replace({ name: 'Error' });
        });
      }
    },

    updateProjectIndex(updateIndex = true) {
      if (updateIndex) {
        let index = 0;
        this.menuItems.forEach(item => {
          if (item.id === this.projectId) {
            this.projectIndex = index;
            this.projectTitle = item.name;
            this.$analytics.track(`Visit Project: ${item.name}`);
          }
          index = index + 1;
        });
      }

      this.showArrowUp = this.projectIndex > 0;
      this.showArrowDown = this.projectIndex >= 0 && this.projectIndex < this.menuItems.length - 1;
    },

    toggleFullscreen() {
      if (this.fullscreenImageUrl) this.isFullscreen = !this.isFullscreen;
    },
    closeFullscreen() {
      this.isFullscreen = false;
    },
  },
}
</script>


<style lang="scss" scoped>
$content-padding-left: $navbar-logo-size + ($navbar-padding * 2);

.project {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  text-align: left;

  @media screen and (max-width: $mobile-screen-width),
  screen and (max-height: $mobile-screen-width) {
    min-height: initial;
  }

  &__button_menu {
    position: fixed;
    z-index: 11;
    top: 2px;
    right: -4px;
    width: 30px
  }

  &__button_up,
  &__button_down,
  &__button_left,
  &__button_right {
    position: fixed;
    //display: none;
    display: flex;
    z-index: 11;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &__icon {
      width: 12px;
      height: 12px;
      margin: 16px;
    }
  }

  @media screen and (max-width: $mobile-screen-width),
  screen and (max-height: $mobile-screen-width) {

    &__button_up,
    &__button_down,
    &__button_left,
    &__button_right {
      display: flex;
    }

    &__button_left,
    &__button_right {
      min-width: 60px;
    }
  }

  &__button_up {
    top: 0;
    right: 100px;
    left: 100px;
    padding-bottom: 16px;

    &__icon {
      transform: rotate(90deg);
    }
  }

  &__button_down {
    bottom: 0;
    right: 100px;
    left: 100px;
    padding-top: 16px;

    &__icon {
      transform: rotate(-90deg);
    }
  }

  &__button_left {
    //top: 100px;
    height: 100px;
    //bottom: 100px;
    bottom: 10px;
    left: 0;
    padding-right: 16px;
  }

  &__button_right {
    //top: 100px;
    height: 100px;
    //bottom: 100px;
    bottom: 10px;
    right: 0;
    padding-left: 16px;

    &__icon {
      transform: rotate(180deg);
    }
  }

  @media screen and (max-width: $mobile-screen-width),
  screen and (max-height: $mobile-screen-width) {

    &__button_left,
    &__button_right {
      top: 100px;
      height: initial;
      bottom: 100px;
      //bottom: 10px;
    }
  }

  &__navbar {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    z-index: 1;

    &__inner {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    &__menuBtn {
      flex: 0 0 auto;
      //position: fixed;
      //top: 2px;
      //right: -4px;
      //width: 30px;
      align-self: flex-start;
      padding-left: 10px;
      padding-bottom: 10px;

      &__img {
        width: 30px;
      }

      &--triangle,
      &--square {
        margin-top: 18px;
        margin-right: 8px;
      }
    }

    @media screen and (max-width: $mobile-screen-width),
    screen and (max-height: $mobile-screen-width) {
      flex-direction: column;
      align-items: start;
    }

    &__logo {
      padding: $navbar-padding;

      @media screen and (max-width: $mobile-screen-width),
      screen and (max-height: $mobile-screen-width) {
        padding: $navbar-padding-mobile;
        padding-bottom: 16px;
      }

      &__link {
        &__img {
          width: $navbar-logo-size;
          height: $navbar-logo-size;

          @media screen and (max-width: $mobile-screen-width),
          screen and (max-height: $mobile-screen-width) {
            width: $navbar-logo-size-mobile;
            height: $navbar-logo-size-mobile;
          }
        }
      }
    }

    &__info {
      //width: 100%;
      flex: 1 1 auto;
      display: flex;
      flex-direction: row;
      align-items: center;

      &__title_container {
        flex: 1 1 auto;
        padding-right: $navbar-padding;
        padding-left: $navbar-logo-size - $navbar-padding + 14px; //$content-padding-left;

        @media screen and (max-width: $mobile-screen-width),
        screen and (max-height: $mobile-screen-width) {
          //padding-left: $navbar-padding-mobile + 6px;
          //padding-right: $navbar-padding-mobile + 6px;
          //padding-bottom: $navbar-padding-mobile;
          padding-left: 6px;
          padding-right: 6px;
          padding-bottom: 0;
        }

        &__title {
          margin: 0;
          font-family: $navbar-title-font;
          font-weight: $navbar-title-font-weight;
          font-size: $navbar-title-size;
          line-height: $navbar-title-line-height;
          color: $theme-color-primary;

          @media screen and (max-width: $mobile-screen-width),
          screen and (max-height: $mobile-screen-width) {
            font-size: $navbar-title-size-mobile;
            line-height: $navbar-title-line-height-mobile;

            display: block;
            text-overflow: ellipsis;
            word-wrap: break-word;
            overflow: auto;
            max-height: $navbar-title-line-height * 1.8;
          }
        }
      }

      &__caption_container {
        max-width: 536px;
        margin-right: 30px;
        padding: 16px 0;
        overflow-y: auto;
        max-height: 116px;

        &__text {
          font-size: 14px;
          line-height: 20px;

          &::v-deep * {
            padding: 0;
            margin: 0;
          }
        }

        @media screen and (max-width: $mobile-screen-width),
        screen and (max-height: $mobile-screen-width) {
          display: none;
        }
      }

      &__navigation {
        display: flex;
        flex-direction: row;
        padding-right: $navbar-padding;

        @media screen and (max-width: $mobile-screen-width),
        screen and (max-height: $mobile-screen-width) {
          display: none;
        }

        &__arrow {
          font-size: 20px;
          color: $theme-color-primary;
          font-weight: bold;
          padding: 14px;
          margin: 0 28px;
          cursor: pointer;
          user-select: none;

          &--disabled {
            cursor: not-allowed;
            opacity: 0.3;
          }
        }
      }
    }
  }

  &__main {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    overflow-y: auto;
    z-index: 0;
    touch-action: pan-x pan-y;

    @media screen and (max-width: $mobile-screen-width),
    screen and (max-height: $mobile-screen-width) {
      overflow-y: hidden;
      overscroll-behavior: none;
    }

    &__menu {
      flex: 0 0 auto;
      width: $content-padding-left;
      margin-top: 20px;
      position: relative;

      &__btn {
        font-size: 12px;
        line-height: 18px;
        font-weight: bold;
        color: #414141;
      }

      @media screen and (max-width: $mobile-screen-width),
      screen and (max-height: $mobile-screen-width) {
        display: none;
      }

      &__component {
        transform-origin: top;
        transform: scale(0.6);
        //top: $navbar-logo-size + ($navbar-padding * 2) + 34px;
        position: sticky;
      }
    }

    &__content {
      //width: 100%;
      position: relative;

      &--has_caption {
        padding-bottom: $caption_container_height;
      }
    }
  }

  &__caption_container {
    background: white;
    position: sticky;
    bottom: 40px;
    left: 0;
    right: 0;
    height: $caption_container_height;
    padding: 10px;
    margin-right: 100px;
    align-self: flex-end;

    overflow-y: hidden;
    max-height: $caption_container_height;
    text-align: right;
    max-width: 500px;
    z-index: 10;

    @media screen and (max-width: $mobile-screen-width),
    screen and (max-height: $mobile-screen-width) {
      max-width: initial;
      margin-right: 0;
      padding: 10px $navbar-padding-mobile + 6px;
      align-self: initial;
      text-align: left;
      bottom: 0;
      background: rgba(255, 255, 255, .9);
    }

    &__caption {
      float: right;

      @media screen and (max-width: $mobile-screen-width),
      screen and (max-height: $mobile-screen-width) {
        float: none;
      }
    }

    &__text {
      //overflow: hidden;
      font-size: 14px;
      line-height: 18px;
      margin: 0;
      //height: $caption_container_height;

      @media screen and (max-width: $mobile-screen-width),
      screen and (max-height: $mobile-screen-width) {
        max-width: initial;
      }

      &::v-deep * {
        padding: 0;
        margin: 0;
      }
    }

    &__read_more {
      font-size: 14px;
      line-height: 18px;
      text-decoration: underline;
      font-weight: bold;
      color: #414141;
      cursor: pointer;
    }
  }
}

//.swiper-container {
//width: 100%;
//height: 100%;
//}
//.swiper-slide {
//}
.swiper-slide-img {
  height: 800px;
  object-fit: cover;
  //margin: auto;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.95);

  display: flex;
  align-items: center;
  padding-bottom: 0 !important;
}

.fullscreen>img {
  width: 100%;
  max-width: 1500px;
  max-height: 100%;
  object-fit: contain;
  margin: 0 auto;
  padding: 20px;
}
</style>