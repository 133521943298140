<template>

  <div class="modal">
    <!--transition name="fade"-->
    <div
      class="modal__frame"
      @click="handleCloseClick" />
    <!--/transition-->
    <transition name="fade">
      <div
        v-if="contentShowing"
        class="modal__content">
        <div class="modal__content__slot">
          <div
            ref="modal_content_slot_content"
            class="modal__content__slot_content">
            <p
              class="modal__content__slot_content__text"
              v-html="contentText" />
            <div
              class="modal__content__slot_content__close"
              @click="handleCloseClick" />
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>

<script>

const noScrollClass = 'no-scroll';

export default {
  name: 'InfoModal',

  // Components ********************************
  components: {
  },

  // Properties ********************************
  props: {
    contentText: String,
  },

  // Data ********************************
  data() {
    return {
      contentShowing: false,
    };
  },

  // Computed ********************************
  computed: {
  },

  watch: {
    /*contentShowing(newValue) {
      if (newValue) {
        const self = this;
        this.$nextTick(function () {
          const img = self.$refs.img;
          const container = self.$refs.modal_content_slot_content;
          if (img) {
            const width = Math.min(container.clientWidth, 300);
            const originalWidth = 844;
            const originalHeight = 488;
            const height = width / (originalWidth / originalHeight);
            img.height = height;
            img.width = width;
          }
        });
      }
    },*/
  },

  beforeCreate() {
    const body = document.body;
    body.classList.add(noScrollClass);
  },

  // Mounted ********************************
  mounted() {
    const self = this;
    this.$nextTick(function () {
      self.contentShowing = true;
      //setTimeout(function () { // wait for close transition
      //self.contentShowing = true;
      //}, 200);
    });
  },

  beforeDestroy() {
    this.contentShowing = false;

    const body = document.body;
    body.classList.remove(noScrollClass);
  },

  // Methods ********************************
  methods: {
    handleCloseClick() {
      this.contentShowing = false;
      const self = this;
      setTimeout(function () { // wait for close transition
        self.$emit('closed', 'close');
      }, 200);
    },
    close() {
      this.contentShowing = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9999;
  user-select: auto;

  &__frame {
    position: absolute;
    background: #414141;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: relative;
    display: block;
    overflow: hidden;

    width: 100%;
    max-width: 400px;

    box-sizing: border-box;
    padding: 12px;

    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);

    &__slot {
      position: relative;
      display: flex;
      flex-direction: column;
      background: white;
      overflow: hidden;
      height: 100%;
      width: 100%;
      border-radius: 4px;
    }

    &__slot_content {
      flex: 1 1;
      overflow-x: hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      padding: 28px $content-padding;

      &__text {
        font-size: 14px;
        line-height: 20px;
        margin: 0;
      }

      &__close {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 50px;
        height: 50px;
        font-size: 0;
        cursor: pointer;

        &:before, &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 2px;
          height: 20px;
          background-color: $theme-color-primary;
          transform: rotate(45deg) translate(-50%, -50%);
          transform-origin: top left;
          content: '';
        }

        &:after {
          transform: rotate(-45deg) translate(-50%, -50%);
        }
      }
    }
  }

  @media screen and (min-width: $mobile-screen-width) {
    &__content {
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      transform: translate(-50%, -50%);
      max-height: 92%;
      max-width: 500px;

      height: initial;
      width: initial;

      &__slot {
        margin: 0 $content-padding;
        border-radius: 4px;
        height: initial;
        width: initial;
      }
    }
  }
}
</style>
