<template>
  <div class="note">
    <div
      class="note__bar"
      :style="barCssVars">
      <span
        :class="topBarClass"/>
      <span
        :class="bottomBarClass"/>
    </div>

    <router-link
      :to="{ name: 'Project', params: { menu: item.menu_name.toLowerCase(), project_id: permalink, projectName: item.name, projectColor: item.color }}"
      :class="buttonClasses"
      :style="buttonCssVars"
      v-tooltip="tooltip"
      @mousedown.native="onClick">
      <span v-if="showButtonText" :class="textClasses">{{ item.name }}</span>
    </router-link>
      <!--@click.native.prevent="onClick"-->
  </div>
</template>

<script>
export default {
  name: 'Note',
  props: {
    item: Object,
    index: Number,
    topBarHidden: Boolean,
    bottomBarHidden: Boolean,
    selected: {
      type: Boolean,
      default: false,
    },
    showButtonText: {
      type: Boolean,
      default: false,
    },
    onSelected: {
      type: Function,
    },
  },
  data() {
    return {
      isAnimating: false,
      isSelected: this.selected,
    };
  },

  // Computed ********************************
  computed: {
    permalink() {
      if (this.item.name) {
        const permalink = this.item.name.toLowerCase().replace(/[^\w\s']|_/g, "").replace(/\s+/g, "-");
        return `${permalink}-${this.item.id}`;
      }
      return this.item.id;
    },
    topBarClass() {
      return {
        'note__bar__top': true,
        'note__bar__top--hidden': this.topBarHidden,
      };
    },
    bottomBarClass() {
      return {
        'note__bar__bottom': true,
        'note__bar__bottom--hidden': this.bottomBarHidden,
      };
    },
    barCssVars() {
      const minWidth = 30;
      const increment = 4;
      const width = minWidth + (this.index * increment);
      return {
        '--width': `${width}px`,
      };
    },
    buttonCssVars() {
      return {
        '--color': this.item.color,
      };
    },
    buttonClasses() {
      return {
        'note__link': true,
        'note__link--animating': this.isAnimating,
        'note__link--selected': this.isSelected || this.$store.getters.isVisited(this.item.id),
      };
    },
    textClasses() {
      const classes = {
        'note__link__text': true,
      }
      if (this.selected) {
        classes['note__link__text--selected'] = true;
      }
      return classes;
    },
    link() {
      return `#${this.item.id}-${this.item.menu_name}-${this.item.name}`;
    },
    tooltip() {
      if (this.showButtonText) {
        return {};
      }
      const tooltipContent = this.tooltipContent();
      tooltipContent.content = this.item.name;
      return tooltipContent;
    },
  },

  watch: {
    selected (val) {
      this.isSelected = val;
    }
  },

  methods: {
    tooltipContent() {
      return {
        content: '',
        offset: this.$constants.TOOLTIP_OFFSET,
        placement: 'right',
        hideOnTargetClick: true,
        delay: { show: 200, hide: 0 },
        classes: '',
      };
    },

    onClick(event) {
      this.$root.clickPosX = event.clientX;
      this.$root.clickPosY = event.clientY;
      /*
      this.isSelected = true;
      if (this.onSelected) {
        if (this.proceedOnMobile) {
          this.onSelected(null);
        } else {
          this.onSelected(this.item.id);
        }
      }*/
    },

    animate() {
      this.isAnimating = true;
      setTimeout(() => {
        this.isAnimating = false;
      }, 1000);
    },
  },
}
</script>

<style lang="scss" scoped>

.note {
  //padding-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  user-select: none;

  &__bar {
    display: flex;
    flex-direction: column;
    //width: 34px;
    width: var(--width);
    /*height: 50px;
    box-sizing: border-box;
    border-left: 10px solid $theme-color-primary;
    border-bottom: 3px solid $theme-color-primary;*/

    &__top {
      position: relative;
      height: 26px;
      box-sizing: border-box;
      border-left: 10px solid $theme-color-primary;
      border-bottom: 3px solid $theme-color-primary;

      &:before {
        content: '';
        position: absolute;
        left: 8px;
        width: 2px;
        top: 0;
        bottom: 0;
        background: $theme-color-primary;
      }

      &--hidden {
        border-left: 0 solid transparent;

        &:before {
          background: none;
        }
      }
    }

    &__bottom {
      position: relative;
      height: 26px;
      box-sizing: border-box;
      border-left: 10px solid $theme-color-primary;

      &:before {
        content: '';
        position: absolute;
        left: 8px;
        width: 2px;
        top: 0;
        bottom: 0;
        background: $theme-color-primary;
      }

      &--hidden {
        border-left: 10px solid transparent;

        &:before {
          background: none;
        }
      }
    }

  }

  &__link {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    border: 3px solid $theme-color-primary;
    border-radius: 50%;
    box-sizing: border-box;
    margin-bottom: 0;

    &:visited {
      color: $theme-color-primary;
    }

    &__text {
      position: absolute;
      left: 80px;
      right: $content-padding;
      text-align: left;
      white-space: nowrap;
      color: $theme-color-primary;
      font-family: $base-font-family;
      font-weight: 600;
      font-size: 22px;

      &--selected {
        opacity: 0.4;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-radius: 50%;
      //background: $theme-color-primary;
      background: var(--color);
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: height .2s ease-in-out 0s, width .2s ease-in-out 0s;
      opacity: 1;
    }

    @media (hover) {
      &:hover:after {
        width: 36px;
        height: 36px;
      }
    }
    &--selected:after {
      width: 36px;
      height: 36px;
    }

    &--animating:after {
      transition: all .5s ease-out 0s;
      transform: translateX(-50%) translateY(-50%) scale(99);
      opacity: 0.8;
      z-index: 1000;
    }
  }

}

</style>