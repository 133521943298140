export default {
  ABOUT_ME_URL: '/about/me',
  ABOUT_BIO_URL: '/about/bio',
  ABOUT_CV_URL: '/about/cv',

  PROJECT_TYPE_IMAGE: '1',
  PROJECT_TYPE_TEXT: '2',
  PROJECT_TYPE_VIDEO: '3',

  THEME_COLOR_PRIMARY: '#2325E2',

  TOOLTIP_OFFSET: 0,

  MOBILE_MAX_WIDTH: 800,
};
