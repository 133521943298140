<template>
  <div class="menu-container">
    <div class="menu">
      <div class="menu__col menu__col--desktop">
        <MenuSquare />
      </div>
      <div class="menu__col menu__col--desktop">
        <MenuNote :items="noteItems" />
      </div>
      <div class="menu__col menu__col--desktop">
        <MenuTriangle :items="triangleItems" />
      </div>

      <div class="menu--inner">
        <div class="menu__col menu__col--mobile">
          <MenuSquare />
        </div>
      </div>
      <div class="menu--inner">
        <div class="menu__col menu__col--mobile">
          <MenuNote :items="noteItems" :onSelected="onNoteMenuClick" />
        </div>
        <div class="menu__col menu__col--mobile">
          <MenuTriangle :items="triangleItems" />
        </div>
      </div>
    </div>
    <!-- <router-link to="/" class="menu-container__intro"><img class="menu-container__intro__arrow_icon"
        :src="navArrowFile" /> intro</router-link> -->
    <ModalNote v-if="menuModalNoteShowing" :items="noteItems" :selectedId="selectedId" @closed="onHideModal" />
  </div>
</template>

<script>
import MenuSquare from '@/components/menu/MenuSquare.vue'
import MenuNote from '@/components/menu/MenuNote.vue'
import MenuTriangle from '@/components/menu/MenuTriangle.vue'
import ModalNote from '@/components/menu/ModalNote.vue'

import navArrowIcon from "@/assets/nav_arrow.svg";

export default {
  name: 'Menu',
  components: {
    MenuSquare,
    MenuNote,
    MenuTriangle,
    ModalNote,
  },
  props: {
  },
  data() {
    return {
      noteItems: [],
      triangleItems: [],
      menuModalNoteShowing: false,
      selectedId: null,
    }
  },

  // Mounted ********************************
  mounted() {
    this.requestMenuItems();
  },

  computed: {
    navArrowFile() {
      return navArrowIcon;
    },
  },

  methods: {
    requestMenuItems() {
      if (!this.$store.getters.menu.notes || !this.$store.getters.menu.triangles) {
        const compare = (a, b) => {
          if (a.order < b.order) {
            return 1;
          }
          if (a.order > b.order) {
            return -1;
          }
          return 0
        };

        //this.$Progress.start();

        this.$http.getMenu(null, (response) => {
          this.noteItems = response.art.sort(compare);
          this.triangleItems = response.collection.sort(compare);

          this.$store.dispatch('setMenuNotes', this.noteItems);
          this.$store.dispatch('setMenuTriangles', this.triangleItems);
        }, (error) => {
          console.log(error);
        });
      } else {
        this.noteItems = this.$store.getters.menu.notes;
        this.triangleItems = this.$store.getters.menu.triangles;
      }
    },
    onNoteMenuClick(selectedId) {
      if (this.$utils.isTouchDevice() && selectedId) {
        this.menuModalNoteShowing = true;
        this.selectedId = selectedId;
      } else {
        this.selectedId = null;
      }
    },
    onHideModal() {
      this.menuModalNoteShowing = false;
      this.selectedId = null;
    },
  },
}
</script>

<style lang="scss" scoped>
/*.menu-container {
  @media screen and (max-width: $mobile-screen-width) {
    max-height: 0;
  }

  &__intro {
    position: fixed;
    bottom: 10px;
    left: 10px;

    font-size: 0.8em;
    padding: 10px 0;
    align-items: center;
    display: flex;

    &__arrow_icon {
      width: 18px;
      height: 18px;
      margin-right: 10px;
      //transform: rotate(180deg);
    }
  }
}*/

.menu {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  max-width: 1180px;
  margin: 0 auto;

  @media screen and (max-width: $mobile-screen-width) {
    flex-direction: column;

    transform: scale(0.75);
    transform-origin: center top;
  }

  &--inner {
    width: 100%;
    position: relative;
    flex-direction: column;
    flex: 1 1 auto;
    display: none;
    align-items: center;

    @media screen and (max-width: $mobile-screen-width) {
      display: flex;
      flex-direction: row;
      max-width: 400px;

      padding-bottom: 20px;
      padding-top: 20px;
    }
  }

  &__col {
    width: 0;
    flex: 1 1 auto;
    transform: scale(0.75);
    transform-origin: center center;

    &--mobile {
      display: none;

      @media screen and (max-width: $mobile-screen-width) {
        display: initial;
        transform-origin: center top;
        //transform: none;
        transform: scale(0.85);
      }
    }

    &--desktop {
      @media screen and (max-width: $mobile-screen-width) {
        display: none;
      }
    }
  }
}
</style>
