import GError from './error';

export default {

  sentry: null,

  processError(error) {
    let err = error;
    if (error) {
      if (error.length > 0) {
        err = new GError(error[0]);
        this.captureError(err);
      } else {
        this.captureException(error);
        err = this.processGenericError();
      }
    } else {
      err = this.processGenericError();
    }
    return err;
  },

  processGenericError() {
    return new GError();
  },


  captureError(/*error*/) {
    // const httpCode = error.getHttpCode();
    // const regex = /^5\d{2}$/g; // http codes 5xx
  },

  captureException(ex) {
    if (this.sentry) {
      this.sentry.captureException(ex);
    }
  },

};
